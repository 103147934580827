// pages/DashboardPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { UserButton, useUser } from '@clerk/clerk-react'
const Sidebar = ({ isOpen, toggleSidebar }) => {
  const menuItems = [
    { id: 1, name: 'Dashboard', icon: 'dashboard.png' },
  ];

  return (
    <>
      {isOpen && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-lg-none"
          style={{ zIndex: 1040 }}
          onClick={toggleSidebar}
        />
      )}

      <div
        className={`sidebar position-fixed top-0 start-0 h-100 bg-purple text-white ${isOpen ? 'show' : ''} d-lg-block`}
        style={{ width: '250px', zIndex: 1045 }}
      >
        <div className="d-flex align-items-center justify-content-between p-3 ps-4">
          <img src="/logo.png" width="148px" alt="logo" />
          {window.innerWidth < 992 && (
            <span onClick={toggleSidebar} style={{ fontSize: '24px' }}>×</span>
          )}
        </div>

        <nav className="mt-2">
          {menuItems.map((item) => (
            <a
              key={item.id}
              href="#"
              className="d-flex align-items-center text-decoration-none text-white-50 px-3 py-2 hover-bg"
            >
              <img height={"18px"} src={`/${item.icon}`} alt="icon" /> &nbsp;&nbsp;&nbsp;
              {item.name}
            </a>
          ))}
        </nav>
      </div>
    </>
  );
};

const DashboardPage = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  // State for sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 992);

  // Determine if the viewport is desktop or mobile
  const isDesktop = window.innerWidth >= 992;

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    if (!isDesktop) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const investments = [
    { id: 1, name: 'Investment - 5', size: '400 KB', lastView: '10 Nov 2024, 10:00 am' },
    { id: 2, name: 'Investment - 4', size: '558 KB', lastView: '08 Nov 2024, 05:20 pm' },
    { id: 3, name: 'Investment - 3', size: '659 KB', lastView: '01 Nov 2024, 08:20 am' },
    { id: 4, name: 'Investment - 2', size: '300 KB', lastView: '28 Oct 2024, 05:50 pm' },
    { id: 5, name: 'Investment - 1', size: '500 KB', lastView: '25 Oct 2024, 04:20 pm' },
  ];

  return (
    <div className="min-vh-100">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <div className={`wrapper ${isDesktop ? 'sidebar-expanded' : ''}`}>
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
          <div className="container-fluid">
            <button className="btn d-lg-none" onClick={toggleSidebar}>
              <img height={"24px"} src="/navicon.png" alt="navicon" />
            </button>
            {isDesktop ? (
              <div>
                <h1 className="navbar-brand mb-0 h1 ms-2 text-purple">Welcome Admin</h1>
                <span className="ms-2">You look awesome !!</span>
              </div>
            ) : (
              <img src="/logo.png" width="148px" alt="logo" />
            )}
            <div className="dropdown">
              <UserButton />
              {/* <button
                className="btn p-0 border-0 bg-transparent"
                id="profileDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="position-relative">
                  <img
                    src={isDesktop ? '/person.png' : '/personw.png'}
                    alt="profile"
                    className="rounded-circle"
                    width="40px"
                  />
                  <span className="position-absolute bottom-0 end-0 translate-middle p-1 bg-success border border-light rounded-circle"></span>
                </div>
              </button>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul> */}
            </div>
          </div>
        </nav>

        {!isDesktop && (
          <div className="mWebHead">
            <h1 className="navbar-brand mb-0 h1 ms-2 text-purple">Welcome Admin</h1>
            <span className="ms-2">You look awesome !!</span>
          </div>
        )}

        <div className="p-4">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4 gap-3">
            <h2 className="text-purple mb-0">Dashboard</h2>
            <select className="form-select" style={{ width: '100%', maxWidth: '441px' }}>
              <option>-- Select your account --</option>
              <option value="1">Account 1</option>
              <option value="2">Account 2</option>
              <option value="3">Account 3</option>
              <option value="4">Account 4</option>
            </select>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" className="form-check-input customInput" />
                      </th>
                      <th>S.NO.</th>
                      <th>NAME</th>
                      <th>FILE SIZE</th>
                      <th>LAST VIEW DATE</th>
                      <th>
                        <img height="18px" src="/pdficon.png" alt="pdficon" /> &nbsp;DOWNLOAD
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {investments.map((investment) => (
                      <tr key={investment.id}>
                        <td>
                          <input type="checkbox" className="form-check-input customInput2" />
                        </td>
                        <td>{investment.id}.</td>
                        <td>{investment.name}</td>
                        <td>{investment.size}</td>
                        <td>{investment.lastView}</td>
                        <td>
                          <img style={{ cursor: 'pointer' }} height="24px" src="/download.png" alt="download" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;