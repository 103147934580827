// pages/LoginPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { SignIn, useUser } from '@clerk/clerk-react'

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser()
  // Constant for URL parameter check
  const hasUserEmailParam = !!location.search.includes('user_email');

  useEffect(() => {
    // Parse URL parameters
    const params = new URLSearchParams(location.search);
    const userEmail = params.get('user_email');
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(password)) {
      setError('Sorry, that password is not strong enough. It must be at least 8 characters long and contain a capital letter.');
      return;
    }

    setError('');
    setIsLoading(true);

    try {
      // Simulate API call
      const response = await new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            success: true,
            auth_token: 'dummy_token_123',
          });
        }, 1000);
      });

      if (response.success) {
        login(response.auth_token);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const validatePassword = (input) => {
    const regex = /^(?=.*[A-Z]).{8,}$/;
    return regex.test(input);
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  if (user) {
    navigate('/dashboard');
  }
  
  return (
    <div className="min-vh-100 d-flex align-items-center justify-content-center bg-purple bg-img-position">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="logoMain">
            <img src="/logo.png" width="210px" alt="logo" />
          </div>
          <div className="col-md-7">
            <h1 className="text-white display-4 mb-4 custom-heading">
              Our Best <br />
              <span className="underline">Investment</span> Will <br />
              Always Be Our Investors
            </h1>
          </div>
          <div className="col-md-5 customWidth">
            {/* <div className="card card-gradient shadow-lg">
              <div className="card-body p-5">
                <h2 className="mb-2">Welcome!</h2>
                <p className="mb-4">
                  {hasUserEmailParam 
                    ? "Please confirm your password below." 
                    : "Enter your credentials to login."}
                </p>
                
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="form-label">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={hasUserEmailParam}
                      readOnly={hasUserEmailParam}
                    />
                  </div>
                  <div style={{position:"relative"}} className="mb-2">
                    <label className="form-label">Confirm your password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      value={password}
                      onChange={handleChange}
                      onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="btn btn-link position-absolute"
                      style={{
                        top: '50%',
                        right: '0.5rem',
                        padding: '1rem',
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      {showPassword ? (
                        <img style={{
                          position:'absolute',
                          top: '25%',
                          right: '7px',
                          padding: 0,
                          border: 'none',
                          background: 'none',
                          cursor: 'pointer'
                      }} height={18} src="/eyeslash.png" alt="eye" />
                      ) : (
                        <img style={{
                          position:'absolute',
                          top: '25%',
                          right: '7px',
                          padding: 0,
                          border: 'none',
                          background: 'none',
                          cursor: 'pointer'
                      }} height={18} src="/eye.png" alt="eye" />
                      )}
                    </button>
                  </div>
                  {error ? <div className="text-danger mt-1 mb-5">{error}</div> : <div className="mb-5 text-end">Forgot password?</div>}
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={isLoading}
                  >
                    {isLoading 
                      ? 'Loading...' 
                      : hasUserEmailParam 
                        ? "Confirm password" 
                        : "Login"}
                  </button>
                </form>
              </div>
            </div> */}
            <SignIn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;